<template>
  <div>
    <el-form :model="formData" label-width="200px">
      <el-row>
        <el-form-item label="调拨/借出单号：">
          <el-input v-model="formData.orderNo" placeholder="请输入调拨单号、借出单号" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="备件编码：">
          <el-input v-model="formData.materialNo" placeholder="请输入备件编码" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-form-item label="出厂SN码：">
        <el-input v-model="formData.factorySn" placeholder="请输入或扫出厂SN码" class="form-item"></el-input>
      </el-form-item>
      <el-row>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="loading">打印校色屏贴纸</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent.vue";

export default {
  name: "ColorCalibrationPrint",
  components: {MessageComponent},
  data() {
    return {
      formData: {
        orderNo: '',
        materialNo: '',
        factorySn: '',
      },
      loading: false
    }
  },
  methods: {
    submit() {
      this.$refs.commitMessage.clear();

      this.formData.orderNo = this.formData.orderNo.trim()
      this.formData.materialNo = this.formData.materialNo.trim()
      this.formData.factorySn = this.formData.factorySn.trim()

      if (!this.formData.orderNo) {
        return this.$refs.commitMessage.showError('请输入调拨单号或借出单号');
      }

      if (!this.formData.materialNo) {
        return this.$refs.commitMessage.showError('请输入备件编码');
      }

      if (!this.formData.factorySn) {
        return this.$refs.commitMessage.showError('请输入或扫出厂SN码');
      }

      /*let is8s = this.formData.factorySn.toUpperCase().startsWith('8S');
      if (!is8s) {
        return this.$refs.commitMessage.showError('出厂SN码必须以"8S"开头');
      }*/

      if (this.$socket.notOk()) {
        return this.$refs.commitMessage.showError('未成功连接打印机小程序');
      }
      this.save();

    },
    save() {
      this.loading = true
      this.$axios.post('colorCalibration/print', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }

        let type = res.data;
        if (type) {
          //打印
          this.$socket.send({
            "requestId": Math.round(Math.random() * 100000000),
            "printType": "calibration",
            "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
            "printVO": {
              "assetSn": this.formData.factorySn,
              "type": type
            }
          })
        }

        this.$refs.commitMessage.showSuccess('打印请求已发送给打印机')
        this.formData.orderNo = ''
        this.formData.materialNo = ''
        this.formData.factorySn = ''
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      });

    },
  }
}
</script>

<style scoped>
.form-item {
  width: 300px;
}
</style>